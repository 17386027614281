import * as Sentry from "@sentry/react";

const SENTRY_DSN_URL = import.meta.env.VITE_SENTRY_DSN_URL

const sentry = {
  initSentryAnalytics() {
    const dnsUrl = SENTRY_DSN_URL;

    if (!dnsUrl) console.warn("No DSN URL found for Sentry")

    Sentry.init({
      dsn: dnsUrl,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
      ],
      tracesSampleRate: 1.0,
      tracePropagationTargets: [/^https:\/\/app\.tapago\.site/],
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    })
  }
}

export default sentry;