import styled from 'styled-components';

const ContainerHTML = styled.div`
  display: flex;
  align-items: stretch;
  flex-direction: column;
  width: 100%;
  max-width: 500px;
  min-height: 100vh;
  background-color: var(--app-primary-bg-color);
`;

const Container = ({ children }: { children: React.ReactNode }) => {
  return(
    <ContainerHTML>{children}</ContainerHTML>
  )
}

export default Container;