import styled from 'styled-components';

import { FaWhatsapp } from 'react-icons/fa';

export interface PlanHeaderProps {
  name: string;
  slug: string;
  gender: 'male' | 'female';
  image_url?: string;
  whatsapp_number?: string;
}

const Container = styled.header`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: end;
  height: 260px;
  overflow: hidden;
  text-align: right;
  padding: 2rem 1rem 1rem 0;
  opacity: 0;
  animation-name: fadeAndRight;
  animation-duration: var(--animation-duration);
  animation-delay: calc(var(--animation-duration) * 0);
  animation-iteration-count: initial;
  animation-fill-mode: forwards;
`;

const Logo = styled.img`
  position: absolute;
  top: 3.5rem;
  left: 3rem;
  width: 50%;
  z-index: 1;
`;

const Avatar = styled.img`
  position: absolute;
  top: 10px;
  left: 0;
  z-index: 10;
  height: 250px;
  width: 50%;
  object-fit: contain;
  object-position: right bottom;
  opacity: 0;
  animation-name: fadeAndUp;
  animation-duration: var(--animation-duration);
  animation-delay: calc(var(--animation-duration) * 1);
  animation-iteration-count: initial;
  animation-fill-mode: forwards;
`;

const Heading = styled.h1`
  width: 50%;
  text-align: right;
  font-size: 30px;
  font-weight: 200;
  line-height: 1.6rem;
  z-index: 12;
  opacity: 0;
  animation-name: fadeAndUp;
  animation-duration: var(--animation-duration);
  animation-delay: calc(var(--animation-duration) * 2);
  animation-iteration-count: initial;
  animation-fill-mode: forwards;
`;

const HeadingLabel = styled.small`
  display: block;
  font-size: 20px;
  font-weight: 600;
`;

const Contacts = styled.div`
  opacity: 0;
  animation-name: fadeAndUp;
  animation-duration: var(--animation-duration);
  animation-delay: calc(var(--animation-duration) * 2);
  animation-iteration-count: initial;
  animation-fill-mode: forwards;
`;

const Whatsapp = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: .5rem;
  font-size: 12px;
  color: var(--color-white);
  background-color: var(--color-whatsapp);
  padding: .3rem .7rem;
  border-radius: 1rem;
`;

const Header = (props: PlanHeaderProps) => {
  const first_name = props.name.split(' ').shift();

  return(
    <Container>
      <Avatar src={props.image_url} />

      <Logo src="/home-background-shape.svg" />

      <Heading>
        <HeadingLabel>{props.gender == 'male' ? 'Treinador' : 'Treinadora'}</HeadingLabel>
        {props.name}
      </Heading>

      <Contacts>
        {
          props.whatsapp_number && 
          <Whatsapp href={`https://wa.me/${props.whatsapp_number}`} target='_blank'>
            <FaWhatsapp size={20} />Fale com {first_name}
          </Whatsapp>
        }
      </Contacts>
    </Container>
  )
}

export default Header;