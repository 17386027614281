import { useEffect } from "react";
import Container from "../components/Shared/Container"
import Loading from "../components/Shared/Loading";
import { useNavigate } from "react-router-dom";

const CheckSlugs = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const trainer_slug = localStorage.getItem('trainer_slug')
    const student_slug = localStorage.getItem('student_slug')

    if (trainer_slug && student_slug) navigate(`/${trainer_slug}/${student_slug}/`)
    else navigate(`/not-found/`)

  }, [])
  
  return(
    <Container>
      <Loading text="carregando informações" />
    </Container>
  )
}

export default CheckSlugs;