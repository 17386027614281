import ReactGA from 'react-ga4';

const GOOGLE_ANALYTICS_TRACKING_ID = import.meta.env.VITE_GOOGLE_ANALYTICS_TRACKING_ID;

const ga = {
  initGoogleAnalytics() {
    const trackingId = GOOGLE_ANALYTICS_TRACKING_ID;

    if (!trackingId) console.warn("No tracking ID found for Google Analytics")

    ReactGA.initialize([
      {
        trackingId,
        gaOptions: {}
      }
    ]);
  }
};

export default ga;