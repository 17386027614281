import styled from 'styled-components';

import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { useState } from 'react';

export interface StudentProps {
  name: string;
  slug: string;
  gender: 'male' | 'female';
  observations?: string;
  end_date?: string,
  start_date?: string,
}

const Container = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: end;
  overflow: hidden;
  text-align: right;
  padding: 1rem;
  background-color: var(--app-secondary-bg-color);
  opacity: 0;
  animation-name: fadeAndLeft;
  animation-duration: var(--animation-duration);
  animation-delay: calc(var(--animation-duration) * 0);
  animation-iteration-count: initial;
  animation-fill-mode: forwards;
`;

const Heading = styled.h2`
  width: 100%;
  text-align: left;
  font-size: 24px;
  font-weight: 200;
  line-height: 1.6rem;
  z-index: 12;
`;

const HeadingLabel = styled.small`
  display: block;
  font-size: 16px;
  font-weight: 600;
`;


const ToggleOpen = styled.a`
  position: absolute;
  right: .5rem;
  top: 1.3rem;
  display: flex;
  height: 40px;
  width: 40px;
  justify-content: center;
  align-items: center;
  z-index: 120;
  opacity: .5;
`;
const ArrowDown = styled(FaChevronDown)`
`;
const ArrowUp = styled(FaChevronUp)`  
`;

const Observations = styled.p`
  display: block;
  width: 100%;
  font-size: 14px;
  text-align: left;
  padding-top: 1rem;
  color: var(--color-gray-400);
  white-space: pre-line;
  opacity: 0;
  animation-name: fadeAndDown;
  animation-duration: var(--animation-duration);
  animation-delay: calc(var(--animation-duration) * 0);
  animation-iteration-count: initial;
  animation-fill-mode: forwards;
`;

const Period = styled.div`
  display: block;
  width: 100%;
  font-size: 14px;
  text-align: left;
  padding-top: 1rem;
  color: var(--color-gray-400);
  white-space: pre-line;
  opacity: 0;
  animation-name: fadeAndDown;
  animation-duration: var(--animation-duration);
  animation-delay: calc(var(--animation-duration) * 0);
  animation-iteration-count: initial;
  animation-fill-mode: forwards;
`;

const StartPeriod = styled.span`
  display: block;
  width: 100%;
  font-size: 12px;
`;

const EndPeriod = styled.span`
  display: block;
  width: 100%;
  font-size: 12px;
`;

const Student = (props: StudentProps) => {
  const [ isOpen, setIsOpen ] = useState(false);

  const handleClick = () => {
    setIsOpen(current => !current);
  }

  const dateOptions:Intl.DateTimeFormatOptions = { 
    // weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };

  return(
    <Container>
        <Heading>
          <HeadingLabel>
            {props.gender == 'male' ? 'Aluno' : 'Aluna'}
          </HeadingLabel>

          {props.name}
        </Heading>
        {props.observations && 
          <ToggleOpen onClick={handleClick}>
            {isOpen && <ArrowUp size={24} />}
            {!isOpen && <ArrowDown size={24} />}
          </ToggleOpen>
        }
        {(props.observations && isOpen) && 
          <Observations>{props.observations}</Observations>
        }

        {(props.start_date || props.end_date) && 
          <Period>
            {props.start_date && 
              <StartPeriod>
                Inicia em {new Date(props.start_date).toLocaleDateString('pt-BR', dateOptions)}
              </StartPeriod>
            }
            {props.end_date && 
              <EndPeriod>
                Termina em {new Date(props.end_date).toLocaleDateString('pt-BR', dateOptions)}
              </EndPeriod>
            }
          </Period>
        }

    </Container>
  )
}

export default Student;