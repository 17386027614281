import { BrowserRouter, Route, Routes } from 'react-router-dom'

import { HelmetProvider } from 'react-helmet-async';

import Plan from './pages/Plan'
import Training from './pages/Training'

import './App.css'
import { TrainingContext, TrainingContextType } from './contexts/TrainingContext'
import { useEffect, useState } from 'react'
import NotFound from './pages/NotFound'
import CheckSlugs from './pages/CheckSlugs';
import { PlanContextProvider } from './contexts/PlanContext';

const API_BASE_URL = import.meta.env.VITE_API_BASE_URL;

function App() {
  const [ training, setTraining ] = useState<Partial <TrainingContextType>>({})
  const [ isLoading, setIsLoading ] = useState(true)
  const [ error, setError ] = useState<any>(null)
  const [ slugs, setSlugs ] = useState<string[]>([])

  useEffect(() => {
    if (slugs.length == 2) {
      const trainer_slug = slugs[0];
      const student_slug = slugs[1];
      
      if (training.trainer?.slug == trainer_slug && training.student?.slug == student_slug) return;

      fetch(`${API_BASE_URL}/app/${trainer_slug}/${student_slug}/`)
        .then(response => response.json())
        .then(json => {
          if (json.message) {
            setError(json.message);
          } else {
            setTraining({...json, isLoading, error})
            localStorage.setItem('trainer_slug', trainer_slug)
            localStorage.setItem('student_slug', student_slug)
          }
        })
        .catch(error => {
          setError(error);
        })
        .finally(() => {
          setIsLoading(false);
        })
    }
  }, [slugs])

  useEffect(() => {
    setTraining({...training, isLoading, error})
  }, [isLoading, error])

  return (
    <HelmetProvider>
      <BrowserRouter>
        <PlanContextProvider>
          <TrainingContext.Provider value={{...training, isLoading, error}}>
            <Routes>
              <Route path="/:trainer_slug/:student_slug" element={<Plan setSlugs={setSlugs} />} />
              <Route path="/:trainer_slug/:student_slug/:training_slug" element={<Training setSlugs={setSlugs} />} />
              <Route path="/" element={<CheckSlugs />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </TrainingContext.Provider>
        </PlanContextProvider>
      </BrowserRouter>
    </HelmetProvider>
  )
}

export default App
