import styled from 'styled-components';
import { colorByIndex } from '../../utils/colorByIndex';
import { timeAgo } from '../../utils/timeAgo';
import { Link } from 'react-router-dom';

interface TrainingProps {
  name: string;
  description?: string;
  slug: string;
}

interface TrainingElementProps extends TrainingProps {
  index: number;
  lastTime?: Date;
  next: boolean;
  current: boolean;
}

export interface TrainingsProps {
  _id: string;
  slug: string;
  trainings: TrainingProps[];
}

interface LastTimeArray {
  date: number,
  index: number,
}

const Container = styled.ul`
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  gap: 1rem;
`;

const TrainingContainer = styled.li<{index: number}>`
  display: flex;
  width: 100%;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  animation-name: fadeAndUp;
  animation-duration: var(--animation-duration);
  animation-delay: ${p => `${p.index*0.1 + 0.8}s`};
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
`;

const TrainingLink = styled(Link)`
  position: relative;
  display: block;
  display: flex;
  align-items: center;
  border-radius: .7rem;
  background-color: var(--color-${props => props.color});
  width: 100%;
  height: 100%;
  padding: 2rem 0;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('/default-background.png');
    background-size: cover;
    background-position: center center;
    opacity: .4;
  }
`;

const Heading = styled.h3`
  position: relative;
  display: block;
  flex: 1;
  text-align: center;
  padding: .3rem 0;
  & * {
    position: relative;
    z-index: 100;
  }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--body-bg-color);
    opacity: .7;
    z-index: 90;
  }
`;

const Name = styled.span`
  display: block;
  font-size: 22px;
  font-weight: 700;
`;

const Description = styled.small`
  display: block;
  font-size: 20px;
  font-weight: 300;
`;

const Next = styled.div`
  position: absolute;
  top: -5px;
  left: -5px;
  z-index: 100;
  & span {
    position: absolute;
    top: 13px;
    left: -2px;
    font-size: 12px;
    font-weight: 600;
    transform: rotate(-45deg);
    z-index: 102;
  }
`;

const Triangle = styled.div<{size: number}>`
  position: absolute;
  top: 0;
  width: 0px;
  height: 0px;
  border-style: solid;
  border-width: ${p => p.size}px ${p => p.size}px 0 0;
  border-color: var(--color-primary) transparent transparent transparent;
  transform: rotate(0deg);
  z-index: 101;
`;

const LastTime = styled.span`
  position: absolute;
  bottom: 8px;
  right: 10px;
  font-size: 11px;
  font-weight: 200;
  text-shadow: 0 0 5px var(--color-black);
  & strong {
    font-weight: 400;
  }
`;

const Training = (training: TrainingElementProps) => {
  return(
    <TrainingContainer key={training.name} index={training.index}>
      <TrainingLink to={`/${training.slug}/`} color={colorByIndex(training.index)}>
        {training.next &&
          <Next>
            <Triangle size={60} />
            <span>próximo</span>
          </Next>
        }
        <Heading>
          <Name>{training.name}</Name>
          <Description>{training.description}</Description>
        </Heading>
        <LastTime>
          {!training.lastTime && <i>não realizado</i>}
          {training.lastTime && <>último treino: <strong>{timeAgo(new Date(training.lastTime))}</strong></>}
        </LastTime>
      </TrainingLink>
    </TrainingContainer>
  )
}

const Trainings = (props: TrainingsProps) => {
  const getStorageLastTime = (training_id: string, slug: string) => {
    const storageLastTime = JSON.parse(localStorage.getItem(`last-time/${training_id}`) || '{}');

    if (typeof storageLastTime !== 'object') return null

    return storageLastTime[slug] || null;
  }

  const getNextTrainingIndex = () => {
    let lastTimeArray:LastTimeArray[] = [];

    props.trainings.map((training, i) => {
      const lastTime = getStorageLastTime(props._id, training.slug);

      if (lastTime) lastTimeArray.push({
        date: lastTime,
        index: i
      })
    })

    if (lastTimeArray.length == 0) return {
      next: 0,
      current: -1,
    };

    lastTimeArray = lastTimeArray.sort((a, b) => b.date - a.date)

    const lastIndex = lastTimeArray[0].index;

    return {
      next: lastIndex + 1 >= props.trainings.length ? 0 : lastIndex + 1,
      current: lastIndex,
    }
  }

  const { next, current } = getNextTrainingIndex();

  return(
    <Container>
      {
        props.trainings.map((training, i) => {
          return (
            <Training { ...training } next={next == i} current={current == i} slug={`${props.slug}/${training.slug}`} key={i} index={i} lastTime={getStorageLastTime(props._id, training.slug)} />
          )
        })
      }
    </Container>
  )
}

export default Trainings;