import styled from 'styled-components';

import { BiError } from 'react-icons/bi';

interface ErrorProps {
  heading?: string;
  text: string;
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  gap: 2rem;
  padding: 2rem;
  text-align: center;
`;

const Heading = styled.h1`
  font-weight: 600;
  font-size: 1.5rem;
  color: var(--color-gray-100);
`;

const Text = styled.span`
  color: var(--color-gray-100);
`;

const Error = (props: ErrorProps) => {
  return(
  <Container>
    <BiError color={`var(--color-red)`} size={60} />
    <Heading>{props.heading || 'Ops'}</Heading>
    <Text>{props.text}</Text>
  </Container>
  );
}

export default Error;