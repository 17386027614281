import { ReactNode, createContext, useContext, useState } from "react";
import { Trainer } from "../interfaces/Trainer";
import { Student } from "../interfaces/Student";
import { Training } from "../interfaces/Training";

interface PlanContextType {
  data?: {
    trainer: Trainer,
    student: Student,
    training: Training,
  },
  slugs? : {
    trainer: string,
    student: string,
  }
  status: {
    isLoading: boolean,
    error?: string,
  }
}

interface PlanContextProviderType {
  children: ReactNode
}

const INITIAL_STATE: PlanContextType = {
  status: {
    isLoading: false,
  }
}

const PlanContext = createContext({
  plan: INITIAL_STATE,
  setPlan: (plan: PlanContextType) => { plan.status },
})

export const PlanContextProvider = ({ children }: PlanContextProviderType) => {
  const [plan, setPlan] = useState(INITIAL_STATE)

  return (
    <PlanContext.Provider 
      value={{ 
        plan,
        setPlan
      }}
    >
      {children}
    </PlanContext.Provider>
  )
}

export const usePlanContext = () => {
  const context = useContext(PlanContext);

  return context;
}