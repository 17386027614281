import styled from 'styled-components';

import { FaChevronDown, FaPlusCircle } from 'react-icons/fa';
import { RxTimer } from 'react-icons/rx';
import { HiOutlineAnnotation } from 'react-icons/hi';
import { GiWeight } from "react-icons/gi";
import { colorByIndex } from '../../utils/colorByIndex';
import { ChangeEvent, useContext, useEffect, useRef, useState } from 'react';
import React from 'react';
import { Exercise as ExerciseI } from '../../interfaces/Training';
import { TrainingContext } from '../../contexts/TrainingContext';
import { useSwipeable } from 'react-swipeable';

interface IsOpenProps {
  isOpen: boolean;
}

interface ExerciseComponentProps extends ExerciseI {
  index: number;
  currentExercise: number | null;
  setCurrentExercise: (a: number | null) => void;
  isEditing: boolean;
  isLastTraining: boolean | null;
  notes: {} | null;
  weight: {} | null;
  slug: string;
}

export interface ExercisesProps {
  exercises: ExerciseI[];
  slug: string;
  index: number;
}

interface LastTimeArray {
  date: number,
  index: number,
}

const weightUnits = {
  kg: {
    name: 'quilogramas',
    symbol: 'Kg',
  },
  lbs: {
    name: 'libras',
    symbol: 'Lbs',
  },
  plates: {
    name: 'placas',
    symbol: 'Plt',
  },
};

const Container = styled.ul<{isOpen: boolean}>`
  position: relative;
  display: flex;
  overflow-x: hidden;
  flex-direction: column;
  align-items: start;
  flex: 1;
  margin: 10px;
  margin-bottom: ${p => p.isOpen ? '155px' : ''};
  background-color: var(--app-secondary-bg-color);
  counter-reset: exercise;
  & > li:last-child a:after {
      border-bottom: none;
    }
`;

const ExerciseContainer = styled.li<{index: number, left: number, done: boolean}>`
  position: relative;
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  counter-increment: exercise;
  animation-name: fadeAndUp;
  animation-duration: var(--animation-duration);
  animation-delay: ${p => `${p.index*0.1 + .6}s`};
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-left: ${p => `${p.left}px`};
  &:before {
    content: "concluir";
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100px;
    background-color: green;
    opacity: ${p => p.left/100};
    z-index: 9;
  }
  & * {
    opacity: ${p => p.done ? .7 : 1};
    transition: all var(--animation-duration);
  }
`;

const ExerciseSwiper = styled.div`
  display: block;
  width: 100%;
  z-index: 10;
`;

const Link = styled.a`
  position: relative;
  display: block;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px 20px 10px 10px;
  gap: 10px;
  &:after {
    position: absolute;
    display: block;
    content: "";
    left: 10px;
    right: 10px;
    bottom: 0;
    border-bottom: 1px solid var(--color-gray-100);
    opacity: .3;
  }
  &.open {
    flex-direction: column;
    padding: 10px;
  }
  &.open figure {
    flex: auto;
    display: flex;
    height: auto;
    width: 100%;
  }
  &.open figure img {
    height: auto;
    width: 100%;
    object-fit: unset;
    opacity: 1;
  }
  &.open > div {
    flex: auto;
    width: 100%;
  }
  & > svg {
    opacity: .5;
  }
`;

const Figure = styled.figure`
  flex: 0 0 60px;
  height: 60px;
  border-radius: 4px;
  background-color: var(--color-${props => props.color});
  overflow: hidden;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: .4;
`;

const Content = styled.div<IsOpenProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  overflow: hidden;
  gap: ${p => p.isOpen ? '5px' : 'unset'};
`;

const Group = styled.span<IsOpenProps>`
  font-size: ${p => p.isOpen ? '14px' : '13px'};
  font-weight: 600;
  opacity: .6;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Name = styled.span<IsOpenProps>`
  display: flex;
  gap: .3rem;;
  align-self: flex-start;
  font-size: ${p => p.isOpen ? '18px' : '16px'};
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Instructions = styled.span<IsOpenProps>`
  font-size: ${p => p.isOpen ? '14px' : '12px'};
  font-weight: 400;
  opacity: .7;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Details = styled.span`
  font-size: 14px;
  font-weight: 400;
  font-style: italic;
  opacity: .7;
  white-space: pre-line;
`;

const Notes = styled.span`
  font-size: 14px;
  font-weight: 400;
  opacity: .7;
  background-color: var(--app-primary-bg-color);
  border-radius: 4px;
  padding: 0.5rem;
  white-space: pre-line;
`;

const MultisetContainer = styled.li<{type: string, index: number}>`
  position: relative;
  width: 100%;
  opacity: 0;
  animation-name: fadeAndUp;
  animation-duration: var(--animation-duration);
  animation-delay: ${p => `${p.index*0.1 + .6}s`};
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  & ul li:not(:last-child) a::after {
    border-bottom: 1px solid var(--color-gray-200);
  }
  & svg.plus {
    position: absolute;
    right: 4.8rem;
    margin-top: -8px;
  }
  &:before {
    content: '';
    position: absolute;
    top: 0;
    width: 5px;
    right: 0;
    bottom: 0;
    background-color: ${p => p.type == 'biset' ? 'var(--color-green)' : 'var(--color-red)'};
    opacity: .4;
  }
`;

const Biset = styled.span<{index: number}>`
  position: absolute;
  right: 3.4rem;
  top: 10px;
  font-size: 12px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 18px;
  border-radius: 15px;
  overflow: visible;
  background-color: var(--color-green);
  opacity: 0;
  animation-name: fadeAndUp;
  animation-duration: 1s;
  animation-delay: ${p => `${p.index*0.1 + .5}s`};
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
`;

const Triset = styled.span<{index: number}>`
  position: absolute;
  right: 3.4rem;
  top: 10px;
  font-size: 12px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 18px;
  border-radius: 15px;
  overflow: visible;
  background-color: var(--color-red);
  opacity: 0;
  animation-name: fadeAndUp;
  animation-duration: 1s;
  animation-delay: ${p => `${p.index*0.1 + .5}s`};
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
`;

const BottomModal = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: var(--app-secondary-bg-color);
  box-shadow: 0 -.5rem 1rem var(--color-black);
  padding: 10px;
  gap: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: var(--color-black);
  animation-name: fadeAndUp;
  animation-duration: var(--animation-duration);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  z-index: 1000;
`;

const ButtonModal = styled.button<{primary?: boolean}>`
  color: var(--color-white);
  border: 0;
  background-color: ${p => p.primary ? 'var(--color-green)' : 'var(--color-red)'};
  font-size: 14px;
  padding: .5rem 1rem;
  z-index: 100;
  width: 100%;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  gap: 10px;
`;

const ButtonWeightOption = styled.button`
  color: var(--color-white);
  border: 0;
  background-color: var(--color-gray);
  font-size: 16px;
  padding: .5rem 1rem;
  z-index: 100;
  width: 100%;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  gap: 10px;
`;

const ButtonsModal = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 45px;
  gap: 10px;
`;

const ButtonNotes = styled.button`
  position: fixed;
  display: flex;
  right: 1.25rem;
  bottom: 1rem;
  justify-content: center;
  align-items: center;
  height: 45px;
  width: 45px;
  border: 0;
  color: var(--color-white);
  background-color: var(--color-primary);
  box-shadow: 0 0 1rem var(--color-black);
  z-index: 100;
  border-radius: 50%;
  opacity: 0;
  animation-name: fadeAndUp;
  animation-duration: var(--animation-duration);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
`;

const ButtonWeight = styled.button`
  position: fixed;
  display: flex;
  right: 5.25rem;
  bottom: 1rem;
  justify-content: center;
  align-items: center;
  height: 45px;
  width: 45px;
  border: 0;
  color: var(--color-white);
  background-color: var(--color-primary);
  box-shadow: 0 0 1rem var(--color-black);
  z-index: 100;
  border-radius: 50%;
  opacity: 0;
  animation-name: fadeAndUp;
  animation-duration: var(--animation-duration);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
`;

const TextareaModal = styled.textarea`
  width: 100%;
  height: 80px;
  background-color: var(--app-primary-bg-color);
  border: 0;
  border-radius: 4px;
  padding: .5rem .7rem;
  font-family: Open Sans;
  letter-spacing: 1px;
  color: var(--color-white);
`;

const WeightTextModal = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--app-primary-bg-color);
  border: 0;
  border-radius: 4px;
  font-family: Open Sans;
  font-size: 2rem;
  letter-spacing: 1px;
  color: var(--color-white);
`;

const getStorageNotes = (training_id: string, training_slug: string, index: number) => {
  const storageNotes = JSON.parse(localStorage.getItem(`notes/${training_id}`) || '{}');
  
  if (typeof storageNotes !== 'object') return null

  if (index == undefined) return storageNotes;

  return storageNotes[`${training_slug}/${index}`] || null;
}

const setStorageNotes = (training_id: string, training_slug: string, index: number, notes: string) => {
  let storageNotes = JSON.parse(localStorage.getItem(`notes/${training_id}`) || '{}');

  storageNotes[`${training_slug}/${index}`] = notes.trim();

  localStorage.setItem(`notes/${training_id}`, JSON.stringify(storageNotes));
}

const getStorageWeight = (training_id: string, training_slug: string, index: number) => {
  const storageWeight = JSON.parse(localStorage.getItem(`weight/${training_id}`) || '{}');
  
  if (typeof storageWeight !== 'object') return null

  if (index == undefined) return storageWeight;

  return storageWeight[`${training_slug}/${index}`] || null;
}

const setStorageWeight = (training_id: string, training_slug: string, index: number, weight: string) => {
  let storageWeight = JSON.parse(localStorage.getItem(`weight/${training_id}`) || '{}');

  storageWeight[`${training_slug}/${index}`] = weight.trim();

  localStorage.setItem(`weight/${training_id}`, JSON.stringify(storageWeight));
}

const ButtonStart = styled.button`
  position: fixed;
  right: 1.25rem;
  bottom: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 1rem;
  gap: .5rem;
  height: 45px;
  width: auto;
  border: 0;
  border-radius: 2rem;
  color: var(--color-white);
  background-color: var(--color-green);
  box-shadow: 0 0 1rem var(--color-black);
  z-index: 100;
  opacity: 0;
  animation-name: fadeAndUp;
  animation-duration: var(--animation-duration);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
`;

const StartTraining = (props: { index: number, slug: string, isLastTraining: boolean | null, startTraining: () => void }) => {
  // const [ _lastTime, setLastTime ] = useState<Date | null>(null);

  const training = useContext(TrainingContext)

  // const getStorageLastTime = (training_id: string, slug: string) => {
  //   const storageLastTime = JSON.parse(localStorage.getItem(`last-time/${training_id}`) || '{}');
    
  //   if (typeof storageLastTime !== 'object') return null

  //   return storageLastTime[slug] || null;
  // }

  // const setStorageLastTime = (training_id: string, slug: string) => {
  //   let storageLastTime = JSON.parse(localStorage.getItem(`last-time/${training_id}`) || '{}');

  //   storageLastTime[slug] = new Date().getTime();

  //   localStorage.setItem(`last-time/${training_id}`, JSON.stringify(storageLastTime));
  // }

  // const isLastTraining = () => {
  //   let lastTimeArray:LastTimeArray[] = [];

  //   if (!training.training) return false;

  //   const training_id = training.training._id;
    
  //   training.training.plan.map((current, i) => {
  //     const lastTime = getStorageLastTime(training_id, current.slug);

  //     if (lastTime) lastTimeArray.push({
  //       date: lastTime,
  //       index: i
  //     })
  //   })

  //   if (lastTimeArray.length == 0) return false;

  //   lastTimeArray = lastTimeArray.sort((a, b) => b.date - a.date)

  //   const lastIndex = lastTimeArray[0].index;

  //   return lastIndex == props.index
  // }
  
  const handleClick = () => {
    if (training.training) {
      // setLastTime(new Date());
      // setStorageLastTime(training.training?._id, props.slug);
      props.startTraining();
    }
  }

  // useEffect(() => {
  //   if (training.training) {
  //     const storageLastTime = getStorageLastTime(training.training?._id, props.slug);
  //     if (storageLastTime) setLastTime(new Date(storageLastTime));
  //   }
  // }, []);

  if (!props.isLastTraining) {
    return (
      <ButtonStart onClick={handleClick}>
        <RxTimer size={20} />
        iniciar treino
      </ButtonStart>
    )
  } else {
    return <></>
  }
}

const NotesModal = (props: {slug: string, currentExercise: number, setNotes: (a: any) => void, isEditing: boolean, setIsEditing: (a: any) => void}) => {
  const { isEditing, setIsEditing } = props;
  const [ currentNotes, setCurrentNotes ] = useState<string>('');

  const training = useContext(TrainingContext)

  const handleOpen = () => {
    setIsEditing(true);
  }

  const handleCancel = () => {
    setIsEditing(false);
  }

  const handleSubmit = () => {
    if (training.training) {
      setStorageNotes(training.training?._id, props.slug, props.currentExercise, currentNotes)

      props.setNotes(currentNotes)
    }

    setIsEditing(false);
  }

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setCurrentNotes(e.target.value);
  }

  const getCurrentNotes = () => {
    if (training.training) {
      const currentNotes = getStorageNotes(training.training?._id, props.slug, props.currentExercise);
      
      setCurrentNotes(currentNotes || '');
    }
  }

  useEffect(() => {
    getCurrentNotes()
  }, [])

  useEffect(() => {
    getCurrentNotes()
    setIsEditing(false);
  }, [props.currentExercise])

  if (!isEditing) return (
    <ButtonNotes onClick={handleOpen}><HiOutlineAnnotation size={24} /></ButtonNotes>
  )

  return (
    <BottomModal>
      <TextareaModal onChange={handleChange} autoFocus placeholder='Minhas anotações...' value={currentNotes} />

      <ButtonsModal>
        <ButtonModal onClick={handleCancel}>cancelar</ButtonModal>
        <ButtonModal onClick={handleSubmit} primary>salvar</ButtonModal>
      </ButtonsModal>
    </BottomModal>
  )
}

const WeightModal = (props: {slug: string, currentExercise: number, setWeight: (a: any) => void, isEditing: boolean, setIsEditing: (a: any) => void}) => {
  const { isEditing, setIsEditing } = props;
  const [ currentWeight, setCurrentWeight ] = useState<string>('');

  const training = useContext(TrainingContext)

  const handleOpen = () => {
    setIsEditing(true);
  }

  const handleCancel = () => {
    setIsEditing(false);
  }

  const handleSubmit = () => {
    if (training.training) {
      setStorageWeight(training.training?._id, props.slug, props.currentExercise, currentWeight)

      props.setWeight(currentWeight)
    }

    setIsEditing(false);
  }

  const getCurrentWeight = () => {
    if (training.training) {
      const currentWeight = getStorageWeight(training.training?._id, props.slug, props.currentExercise);
      
      setCurrentWeight(currentWeight || '');
    }
  }

  useEffect(() => {
    getCurrentWeight()
  }, [])

  useEffect(() => {
    getCurrentWeight()
    setIsEditing(false);
  }, [props.currentExercise])

  if (!isEditing) return (
    <ButtonWeight onClick={handleOpen}><GiWeight size={24} /></ButtonWeight>
  )

  const getWeightValue = (weight: string) => {
    if (weight.split(' ').length < 2) return 0;

    return parseFloat(weight.split(' ')[0]) || 0;
  }
  const getWeightUnit = (weight: string) => {
    if (weight.split(' ').length < 2) return weightUnits.kg.symbol;
    return weight.split(' ')[1];
  }

  const handleWeightValueChange = (props: { action: 'add' | 'remove', value: number }) => {
    const newValue = getWeightValue(currentWeight) + (props.action == 'add' ? props.value : props.value * -1);

    if (newValue <= 0) {
      setCurrentWeight('');
      return;
    }

    setCurrentWeight(`${newValue} ${getWeightUnit(currentWeight)}`);
  }

  const handleWeightUnitChange = (unit: string) => {
    setCurrentWeight(`${getWeightValue(currentWeight)} ${unit}`);
  }

  return (
    <BottomModal>
      <WeightTextModal>
        {isNaN(getWeightValue(currentWeight)) || getWeightValue(currentWeight) == 0 ? '' : `${getWeightValue(currentWeight)}${getWeightUnit(currentWeight)}`}
      </WeightTextModal>

      <ButtonsModal>
        {(() => {
          const options = [];
          for (const [key, value] of Object.entries(weightUnits)) {
            options.push(<ButtonWeightOption key={key} onClick={(() => handleWeightUnitChange(value.symbol))}>{value.name}</ButtonWeightOption>);
          }
          return options;
        })()}
      </ButtonsModal>

      <ButtonsModal>
        <ButtonWeightOption onClick={() => handleWeightValueChange({ action: 'remove', value: .5 })}>- 0,5</ButtonWeightOption>
        <ButtonWeightOption onClick={() => handleWeightValueChange({ action: 'add', value: .5 })}>+ 0,5</ButtonWeightOption>
      </ButtonsModal>

      <ButtonsModal>
      <ButtonWeightOption onClick={() => handleWeightValueChange({ action: 'remove', value: 1 })}>- 1</ButtonWeightOption>
        <ButtonWeightOption onClick={() => handleWeightValueChange({ action: 'add', value: 1 })}>+ 1</ButtonWeightOption>
      </ButtonsModal>

      <ButtonsModal>
      <ButtonWeightOption onClick={() => handleWeightValueChange({ action: 'remove', value: 5 })}>- 5</ButtonWeightOption>
        <ButtonWeightOption onClick={() => handleWeightValueChange({ action: 'add', value: 5 })}>+ 5</ButtonWeightOption>
      </ButtonsModal>

      <ButtonsModal>
      <ButtonWeightOption onClick={() => handleWeightValueChange({ action: 'remove', value: 10 })}>- 10</ButtonWeightOption>
        <ButtonWeightOption onClick={() => handleWeightValueChange({ action: 'add', value: 10 })}>+ 10</ButtonWeightOption>
      </ButtonsModal>

      <ButtonsModal>
      <ButtonWeightOption onClick={() => handleWeightValueChange({ action: 'remove', value: 50 })}>- 50</ButtonWeightOption>
        <ButtonWeightOption onClick={() => handleWeightValueChange({ action: 'add', value: 50 })}>+ 50</ButtonWeightOption>
      </ButtonsModal>

      <ButtonsModal>
        <ButtonModal onClick={handleCancel}>cancelar</ButtonModal>
        <ButtonModal onClick={handleSubmit} primary>salvar</ButtonModal>
      </ButtonsModal>
    </BottomModal>
  )
}

const Multiset = ({children, index}: {children: JSX.Element[], index: number}) => {
  const type = children.length == 2 ? 'biset' : 'triset';

  const Plus = ({type}: {type: 'biset' | 'triset'}) => {
    return <FaPlusCircle className='plus' size={16} color={type == 'biset' ? 'var(--color-green)' : 'var(--color-red)' } />
  }

  return(
    <MultisetContainer type={type} index={index}>
      {type == 'biset' ? <Biset index={index}>biset</Biset> : <Triset index={index}>triset</Triset>}
      <ul>
        {
          React.Children.map(children, (child, i) => {
            return(
              <>
                {child}
                {i < children.length - 1 && <Plus type={type} />}
              </>
            )
          })
        }
      </ul>
    </MultisetContainer>
  )
}

const Exercise = (exercise: ExerciseComponentProps) => {
  let series = `${exercise.series} ` + (exercise.series == 1 ? 'série' : 'séries');
  
  const training = useContext(TrainingContext)

  const training_id = training.training ? training.training._id : '';

  const [ isOpen, setIsOpen ] = useState(false);
  
  const element = useRef<null | HTMLLIElement>(null); 
  
  const [ currentNotes, setCurrentNotes ] = useState<string | null>(null);
  const [ currentWeight, setCurrentWeight ] = useState<string | null>(null);
  
  const [ isDone, setIsDone ] = useState(false);
  
  const notes = exercise.notes;
  const weight = exercise.weight;
  
  const handleClick = () => {
    if (!isOpen) {
      exercise.setCurrentExercise(exercise.index);
    } else {
      exercise.setCurrentExercise(null);
    }
  }

  useEffect(() => {
    const storageNotes = getStorageNotes(training_id, exercise.slug, exercise.index) || '';
    setCurrentNotes(storageNotes);
  }, []);
  
  useEffect(() => {
    const storageNotes = getStorageNotes(training_id, exercise.slug, exercise.index) || '';
    setCurrentNotes(storageNotes);
  }, [notes]);

  useEffect(() => {
    const storageWeight = getStorageWeight(training_id, exercise.slug, exercise.index) || '';
    setCurrentWeight(storageWeight);
  }, []);
  
  useEffect(() => {
    const storageWeight = getStorageWeight(training_id, exercise.slug, exercise.index) || '';
    setCurrentWeight(storageWeight);
  }, [weight]);


  useEffect(() => {
    if (exercise.currentExercise === exercise.index) {
      setIsOpen(true);

      window.setTimeout(() => {
        if (!element.current) return;

        element.current.scrollIntoView({ 'behavior': 'smooth' })
      }, 100)
    } else if (isOpen) {
      setIsOpen(false);
    }

  }, [exercise.currentExercise, exercise.isEditing])

  const swipeHandlers = useSwipeable({
    onSwiping: (e) => {
      if (isDone) return;
      setMarginLeft(e.deltaX >= 100 ? 100 : e.deltaX)
    },
    onSwiped: (_e) => {
      setMarginLeft((current) => {
        if (current >= 100) {
          setIsDone(true);
        }
        return 0;
      });
    },
    delta: { right: 20 },
    preventScrollOnSwipe: false,
  });

  const [ marginLeft, setMarginLeft ] = useState(0);

  if (exercise.isLastTraining) {
    return(
      <ExerciseContainer key={exercise.name} ref={element} index={exercise.index} left={marginLeft} done={isDone}>
        <ExerciseSwiper {...swipeHandlers}>
          <Link onClick={handleClick} className={isOpen ? 'open' : ''}>

            {isOpen && 
              <Content isOpen={isOpen}>
                <Group isOpen={isOpen}>
                  {exercise.group}
                </Group>
                
                <Name isOpen={isOpen}>
                  {exercise.name}
                </Name>
              </Content>
            }

            <Figure color={exercise.index ? colorByIndex(exercise.index) : colorByIndex(0)}>
              <Image src={exercise.ilustration_url} />
            </Figure>
            
            <Content isOpen={isOpen}>
              {!isOpen &&
                <>
                  <Group isOpen={isOpen}>
                    {exercise.group}
                  </Group>
                  
                  <Name isOpen={isOpen}>
                    {(!isOpen && (currentNotes)) && <HiOutlineAnnotation />}
                    {(!isOpen && (currentWeight)) && <GiWeight />}
                    {exercise.name}
                  </Name>

                </>
              }
              
              <Instructions isOpen={isOpen}>
                {(!isOpen && exercise.details) && '*'}
                {series} |&nbsp;
                {exercise.repetitions}
                {exercise.rest && ` | ${exercise.rest}s descanso`}
              </Instructions>
              
              {(isOpen && exercise.details) && <Details>*{exercise.details}</Details>}

              {(isOpen && currentNotes) && <Notes><strong>Anotações:</strong> {currentNotes}</Notes>}

              {(isOpen && currentWeight) && <Notes><strong>Carga:</strong> {currentWeight}</Notes>}

            </Content>
            {!isOpen && <FaChevronDown size={24} />}
          </Link>
        </ExerciseSwiper>
      </ExerciseContainer>
    )
  }

  return(
    <ExerciseContainer key={exercise.name} ref={element} index={exercise.index} left={marginLeft} done={isDone}>
      <Link onClick={handleClick} className={isOpen ? 'open' : ''}>

        {isOpen && 
          <Content isOpen={isOpen}>
            <Group isOpen={isOpen}>
              {exercise.group}
            </Group>
            
            <Name isOpen={isOpen}>
              {exercise.name}
            </Name>
          </Content>
        }

        <Figure color={exercise.index ? colorByIndex(exercise.index) : colorByIndex(0)}>
          <Image src={exercise.ilustration_url} />
        </Figure>
        
        <Content isOpen={isOpen}>
          {!isOpen &&
            <>
              <Group isOpen={isOpen}>
                {exercise.group}
              </Group>
              
              <Name isOpen={isOpen}>
                {(!isOpen && (currentNotes)) && <HiOutlineAnnotation />}
                {(!isOpen && (currentWeight)) && <GiWeight />}
                {exercise.name}
              </Name>

            </>
          }
          
          <Instructions isOpen={isOpen}>
            {(!isOpen && exercise.details) && '*'}
            {series} |&nbsp;
            {exercise.repetitions}
            {exercise.rest && ` | ${exercise.rest}s descanso`}
          </Instructions>
          
          {(isOpen && exercise.details) && <Details>*{exercise.details}</Details>}

          {(isOpen && currentNotes) && <Notes><strong>Anotações:</strong> {currentNotes}</Notes>}

          {(isOpen && currentWeight) && <Notes><strong>Carga:</strong> {currentWeight}</Notes>}

        </Content>
        {!isOpen && <FaChevronDown size={24} />}
      </Link>
    </ExerciseContainer>
  )
}

const Exercises = (props: ExercisesProps) => {
  let biset: JSX.Element[] = [];
  let triset: JSX.Element[] = [];

  const [ isEditingNotes, setIsEditingNotes ] = useState<boolean>(false);
  const [ isEditingWeight, setIsEditingWeight ] = useState<boolean>(false);

  const [ notes, setNotes ] = useState('')
  const [ weight, setWeight ] = useState('')

  const [ currentExercise, setCurrentExercise ] = useState<number | null>(null)

  const training = useContext(TrainingContext)
  
  const [ isLastTraining, setIsLastTraining ] = useState<boolean>(false)

  const getStorageLastTime = (training_id: string, slug: string) => {
    const storageLastTime = JSON.parse(localStorage.getItem(`last-time/${training_id}`) || '{}');
    
    if (typeof storageLastTime !== 'object') return null

    return storageLastTime[slug] || null;
  }

  const startTraining = () => {
    if (!training.training) return;

    setIsLastTraining(true);

    setStorageLastTime(training.training._id, props.slug);
  }

  const setStorageLastTime = (training_id: string, slug: string) => {
    let lastTrainingIndex = null;

    let storageLastTime = JSON.parse(localStorage.getItem(`last-time/${training_id}`) || '{}');
    
    if (lastTrainingIndex == props.index) return;

    storageLastTime[slug] = new Date().getTime();

    localStorage.setItem(`last-time/${training_id}`, JSON.stringify(storageLastTime));
  }

  useEffect(() => {
    if (!training.training) return;
    
    let lastTimeArray:LastTimeArray[] = [];

    const training_id = training.training._id;
    
    training.training.plan.map((current, i) => {
      const lastTime = getStorageLastTime(training_id, current.slug);

      if (lastTime) lastTimeArray.push({
        date: lastTime,
        index: i
      })
    })

    if (lastTimeArray.length == 0) return;

    lastTimeArray = lastTimeArray.sort((a, b) => b.date - a.date)
    const lastIndex = lastTimeArray[0].index;

    if (lastIndex == props.index) {
      console.log('passou no if')
      setIsLastTraining(true);
    }

  }, []);

  // useEffect(() => {
  //   if (training.training) {
  //     console.log('atualizou...')
  //     //setStorageLastTime(training.training._id, props.slug);
  //   }
  // }, [isLastTraining]);

  return(
    <Container isOpen={isEditingNotes || isEditingWeight}>
      {
        props.exercises.map((exercise, i) => {
          if (exercise.biset || biset.length > 0) {
            biset.push(
              <Exercise
                { ...exercise }
                notes={notes}
                weight={weight}
                slug={props.slug}
                key={`exercise-${i}`}
                index={i}
                currentExercise={currentExercise}
                setCurrentExercise={setCurrentExercise}
                isEditing={isEditingNotes || isEditingWeight}
                isLastTraining={isLastTraining}
              />);
          }

          if (biset.length > 1) {
            const bisetClone = biset;
            biset = [];
            return <Multiset key={`multiset-${i}`} index={i}>{ ...bisetClone }</Multiset>;
          }
          
          if (exercise.triset || triset.length > 0) {
            triset.push(
              <Exercise
                { ...exercise }
                notes={notes}
                weight={weight}
                slug={props.slug}
                key={`exercise-${i}`}
                index={i}
                currentExercise={currentExercise}
                setCurrentExercise={setCurrentExercise}
                isEditing={isEditingNotes || isEditingWeight}
                isLastTraining={isLastTraining}
              />
            );
          }

          if (triset.length > 2) {
            const trisetClone = triset;
            triset = [];
            return <Multiset key={`multiset-${i}`} index={i}>{ ...trisetClone }</Multiset>;
          }
          
          if (biset.length == 0 && triset.length == 0) {
            return (
              <Exercise
                { ...exercise }
                notes={notes}
                weight={weight}
                slug={props.slug}
                key={`exercise-${i}`}
                index={i}
                currentExercise={currentExercise}
                setCurrentExercise={setCurrentExercise}
                isEditing={isEditingNotes || isEditingWeight} 
                isLastTraining={isLastTraining}
              />
            )
          }
        })
      }

      {currentExercise != null && 
        <>
          <NotesModal slug={props.slug} currentExercise={currentExercise} isEditing={isEditingNotes} setIsEditing={setIsEditingNotes} setNotes={setNotes} />
          <WeightModal slug={props.slug} currentExercise={currentExercise} isEditing={isEditingWeight} setIsEditing={setIsEditingWeight} setWeight={setWeight} />
        </>
      }

      {!currentExercise && 
        <StartTraining index={props.index} slug={props.slug} isLastTraining={isLastTraining} startTraining={startTraining} />
      }
    </Container>
  )
}

export default Exercises;