import { Dispatch, SetStateAction, useContext, useEffect } from "react";
import Container from "../components/Shared/Container"
import Exercises, { ExercisesProps } from "../components/Training/Exercises";
import Header, { TrainingHeaderProps } from "../components/Training/Header";
import { TrainingContext } from "../contexts/TrainingContext";
import { useParams } from "react-router-dom";
import Loading from "../components/Shared/Loading";
import Error from "../components/Shared/Error";
import { Helmet } from "react-helmet-async";

const Training = (props: {setSlugs: Dispatch<SetStateAction<string[]>>}) => {
  const training = useContext(TrainingContext)

  const setSlugs = props.setSlugs;

  const { trainer_slug, student_slug, training_slug } = useParams();

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto"
    });

    if (trainer_slug && student_slug) 
      setSlugs([ trainer_slug, student_slug]);
  }, [])

  if (training.error) return(
    <Container>
      <Error text="Alguma coisa deu errado. Verifique se seu treino ainda está ativo." />
    </Container>
  )

  if (training.isLoading) return(
    <Container>
      <Loading text="carregando seu treino..." />
    </Container>
  )

  if (!training.trainer || !training.student || !training.training || !training.training.plan) return(
    <Container>
      <Error text="Alguma coisa deu errado. Verifique se seu treino ainda está ativo." />
    </Container>
  )

  let currentTraining: TrainingHeaderProps | null = null;
  let currentExercises: ExercisesProps | null = null;

  training.training.plan.map((current, i) => {
    if (current.slug == training_slug) {
      currentTraining = {
        name: current.name,
        slug: current.slug,
        description: current.description,
        observations: current.observations,
        index: i,
      }
      currentExercises = { 
        exercises: current.exercises,
        slug: current.slug,
        index: i
      }
    }
  })

  if (!currentTraining || !currentExercises) return(
    <Container>
      <Error text="Alguma coisa deu errado. Verifique se seu treino ainda está ativo." />
    </Container>
  )

  const pageTitle = `${(currentTraining as TrainingHeaderProps).name} de ${training.student.name.split(' ').shift()} feito por ${training.trainer.name.split(' ').shift()} | #TaPago` 
  const pageDescription = `Seu treino de ${(currentTraining as TrainingHeaderProps).description} com ${(currentExercises as ExercisesProps).exercises.length} exercícios preparado por ${training.trainer.name.split(' ').shift()}.`

  return(
    <Container>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
      </Helmet>

      <Header { ...currentTraining as TrainingHeaderProps } />

      <Exercises { ...currentExercises as ExercisesProps } />
    </Container>
  )
}

export default Training;