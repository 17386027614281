import styled from 'styled-components';

import { ImSpinner2 } from 'react-icons/im';

interface LoadingProps {
  text: string;
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex: 1;
  gap: 2rem;
  padding: 2rem;
  text-align: center;
  & svg {
    animation-name: rotating;
    animation-duration: .7s;
    animation-delay: 0;
    animation-iteration-count: infinite;
  }
`;

const Text = styled.span`
  color: var(--color-gray-100);
`;

const Loading = (props: LoadingProps) => {
  return(
  <Container>
    <ImSpinner2 color={`var(--color-gray-200)`} size={60} />
    <Text>{props.text}</Text>
  </Container>
  );
}

export default Loading;