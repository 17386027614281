import { createContext } from "react";
import { Trainer } from "../interfaces/Trainer";
import { Student } from "../interfaces/Student";
import { Training } from "../interfaces/Training";

export interface TrainingContextType {
  trainer: Trainer,
  student: Student,
  training: Training,
  isLoading: boolean,
  error: string
}

export const TrainingContext = createContext<Partial <TrainingContextType>>({})