import TimeAgo from 'javascript-time-ago'

import pt from 'javascript-time-ago/locale/pt'

TimeAgo.addDefaultLocale(pt)

export const timeAgo = (date:Date) =>{
  return new TimeAgo('pt').format(date, 'round-minute');
} 

