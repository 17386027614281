import Container from "../components/Shared/Container"
import Error from "../components/Shared/Error";

const NotFound = () => {
  return(
    <Container>
      <Error heading="Página não encontrada" text="Não conseguimos encontrar o recurso que você está procurando. Verifique se o endereço está correto." />
    </Container>
  )
}

export default NotFound;