import styled from 'styled-components';

import { FaChevronCircleLeft } from 'react-icons/fa';
import { timeAgo } from '../../utils/timeAgo';
import { useContext, useEffect, useState } from 'react';
import { TrainingContext } from '../../contexts/TrainingContext';
import { Link } from 'react-router-dom';

export interface TrainingHeaderProps {
  name: string;
  slug: string;
  description?: string;
  observations?: string;
  index: number;
}

const Container = styled.header`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  padding: 3.5rem 0 2rem 0;
  border-bottom: 4px solid #fff;
  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url('/default-background.png');
    background-size: cover;
    background-position: center center;
    opacity: .2;
  }
`;

const Heading = styled.h1`
  font-size: 32px;
  font-weight: 700;
  line-height: 1.5rem;
  z-index: 12;
  text-align: center;
  opacity: 0;
  animation-name: fade;
  animation-duration: var(--animation-duration);
  animation-delay: calc(var(--animation-duration) * 0);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
`;

const Description = styled.small`
  display: block;
  font-size: 26px;
  font-weight: 300;
  opacity: 0;
  animation-name: fadeAndUp;
  animation-duration: var(--animation-duration);
  animation-delay: calc(var(--animation-duration) * 0);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
`;

const Back = styled(Link)`
  position: absolute;
  top: 1rem;
  left: 1rem;
  color: var(--color-white);
  opacity: 0;
  animation-name: fade;
  animation-duration: var(--animation-duration);
  animation-delay: calc(var(--animation-duration) * 2);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
`;

const LastTime = styled.span`
  position: absolute;
  top: 20px;
  right: 1rem;
  font-size: 11px;
  font-weight: 200;
  text-shadow: 0 0 5px var(--color-black);
  opacity: 0;
  animation-name: fadeAndUp;
  animation-duration: var(--animation-duration);
  animation-delay: calc(var(--animation-duration) * 1);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
  & strong {
    font-weight: 400;
  }
`;

const Observations = styled.p`
  font-size: 14px;
  font-weight: 200;
  text-shadow: 0 0 5px var(--color-black);
  opacity: 0;
  animation-name: fadeAndUp;
  animation-duration: var(--animation-duration);
  animation-delay: calc(var(--animation-duration) * 0);
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
`;

const Header = (props: TrainingHeaderProps) => {
  const [lastTime, setLastTime] = useState<Date | null>(null);

  const training = useContext(TrainingContext)

  const getStorageLastTime = (training_id: string, slug: string) => {
    const storageLastTime = JSON.parse(localStorage.getItem(`last-time/${training_id}`) || '{}');

    if (typeof storageLastTime !== 'object') return null

    return storageLastTime[slug] || null;
  }

  useEffect(() => {
    if (training.training) {
      const storageLastTime = getStorageLastTime(training.training?._id, props.slug);
      if (storageLastTime) setLastTime(new Date(storageLastTime));
    }
  }, []);

  return (
    <Container>
      <Back to={`/${training.trainer?.slug}/${training.student?.slug}/`}>
        <FaChevronCircleLeft size={30} />
      </Back>

      <LastTime>
        {!lastTime && <i>não realizado</i>}
        {lastTime && <>último treino: <strong>{timeAgo(lastTime)}</strong></>}
      </LastTime>

      <Heading>
        {props.name}
        <Description>{props.description}</Description>
      </Heading>

      {props.observations &&
        <Observations>{props.observations}</Observations>
      }
    </Container>
  )
}

export default Header;