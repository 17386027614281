import { Dispatch, SetStateAction, useContext, useEffect } from "react";
import Container from "../components/Shared/Container"
import Header from '../components/Plan/Header';
import Student from '../components/Plan/Student';
import Trainings from "../components/Plan/Trainings";
import { TrainingContext } from "../contexts/TrainingContext";
import { useParams } from "react-router-dom";
import Loading from "../components/Shared/Loading";
import Error from "../components/Shared/Error";
import { Helmet } from "react-helmet-async";

const Plan = (props: {setSlugs: Dispatch<SetStateAction<string[]>>}) => {
  const training = useContext(TrainingContext)

  const setSlugs = props.setSlugs;

  const { trainer_slug, student_slug } = useParams();

  useEffect(() => {
    document.documentElement.scrollTo({
      top: 0,
      left: 0,
      behavior: "auto"
    });

    if (trainer_slug && student_slug)
      setSlugs([ trainer_slug, student_slug]);
  }, [])

  if (training.error) return(
    <Container>
      <Error text="Alguma coisa deu errado. Verifique se seu treino ainda está ativo." />
    </Container>
  )

  if (training.isLoading) return(
    <Container>
      <Loading text="carregando seu treino..." />
    </Container>
  )

  if (!training.trainer || !training.student || !training.training) return(
    <Container>
      <Error text="Alguma coisa deu errado. Verifique se seu treino ainda está ativo." />
    </Container>
  )

  const pageTitle = `Treino de ${training.student.name.split(' ').shift()} feito por ${training.trainer.name.split(' ').shift()} | #TaPago` 
  const pageDescription = `${training.trainer.name.split(' ').shift()} preparou esse plano com ${training.training.plan.length} treinos especialmente para você!`

  return(
    <Container>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
      </Helmet>

      <Header 
        name={training.trainer.name}
        slug={training.trainer.slug}
        gender={training.trainer.gender}
        image_url={training.trainer.image_url}
        whatsapp_number={training.trainer.whatsapp_number}
      />

      <Student
        name={training.student.name}
        slug={training.student.slug}
        gender={training.student.gender}
        observations={training.training.observations}
        start_date={training.training.start_date}
        end_date={training.training.end_date}
      />

      <Trainings
        _id={training.training._id}
        slug={`${training.trainer?.slug}/${training.student?.slug}`}
        trainings={training.training.plan.map(currentTraining => 
          ({ 
            name: currentTraining.name, 
            description: currentTraining.description,
            slug: currentTraining.slug,
          })
        )}
      />
    </Container>
  )
}

export default Plan;